/* You can add global styles to this file, and also import other style files */

.cal-month-view .cal-open-day-events {
    display: none !important;
}
.cal-open-day-events {
    display: none !important;
}
.cal-day-badge {
    display: none !important;
}
.cal-event {
    width: 20px !important;
    height: 20px !important;
    border-radius: 3px !important;
}

.cal-month-view .cal-cell-top {
    min-height: 60px !important;
    cursor: pointer;
}
.empty-row {
    text-align: center;
    padding: 30px;
    width: 100%;
}

.btn {
    margin: 0 !important;
}

.nav-pills {
    background: #cfe7eb !important;
}

.largerCheckbox {
    width: 20px !important;
    height: 20px !important;
}
.mt-16 {
    margin-top: 16px;
}
.mlr {
    margin-right: 10px !important;
    margin-left: 10px !important;
}
.formpad {
    padding: 10px 20px 0 20px !important;
    margin-bottom: 0 !important;
}

/* .nav-tabs .nav-link.active {
    background-color: #009ca0 !important;
    color: white !important;
    font-weight: 500;
    border: none !important;
}
.nav-tabs {
    background: #daefef !important;
} */
.nav-pills {
    background: #cfe7eb !important;
    font-weight: 500;
}
.catering {
    background: #ab40e9 !important;
}
.delivery {
    background: #ff5e31 !important;
}
.setup {
    background: #5cc7ff !important;
}
.hospitality {
    background: #28a745 !important;
}
.feast {
    background: #ffc107 !important;
}
.highlight{
    color: #001eff !important;
}

.span-center{
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.error-message {
  color: #FF586B;
}

.disabled-button {
  pointer-events: none;
  color: #888989 !important;
  background: #efefef !important;
}

.invalid-input {
  border-color: #FF586B !important;
}

.is-loading,
.is-loading:enabled:hover {
  background-image: url('./assets/img/small-loader.svg');
  background-repeat: no-repeat;
  background-position: center center;

  font-size: 0;
}

.empty-container {
  text-align: center;
  font-size: 18px;
}
