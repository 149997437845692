.ngx-datatable.paging .datatable-footer {
  background: #424242;
  color: #ededed;
  margin-top: -1px;
}

.ngx-datatable.paging .datatable-footer .datatable-pager {
  margin: 0 10px;
  vertical-align: top;
}

.ngx-datatable.paging .datatable-footer .datatable-pager ul li {
  margin: 10px 0;
}

.ngx-datatable.paging .datatable-footer .datatable-pager ul li:not(.disabled).active a, .ngx-datatable.paging .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
  background-color: #545454;
  font-weight: bold;
}

.ngx-datatable.paging .datatable-footer .datatable-pager a {
  height: 22px;
  min-width: 24px;
  line-height: 22px;
  padding: 0;
  border-radius: 3px;
  margin: 0 3px;
  text-align: center;
  text-decoration: none;
  color: #ededed;
}

.ngx-datatable .datatable-body .datatable-body-row:has(span.unread) {
  background-color: #a3cda1;
}
