// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.

.noUi-target,
.noUi-target * {
    -webkit-touch-callout: none;
    -ms-touch-action: none;
    user-select: none;
    box-sizing: border-box;
}
.noUi-base {
    width: 100%;
    height: 100%;
    position: relative;
}
.noUi-origin {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
}
.noUi-handle {
    // position: relative;
    z-index: 1;
    box-sizing: border-box;
}
.noUi-stacking .noUi-handle {
    z-index: 10;
}
// WARNING: Property with star prefix found. Checks for the star property hack (targets IE6/7) (star-property-hack) Browsers: All
.noUi-state-tap .noUi-origin {
    transition: left 0.3s, top 0.3s;
}
.noUi-state-drag * {
    cursor: inherit !important;
}
.noUi-horizontal {
    height: 10px;
}
.noUi-handle {
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    left: -10px;
    top: -6px;
    cursor: pointer;
    border-radius: 100%;
    transition: all 0.2s ease-out;
    border: 5px solid theme-color("primary");
    background: $white;

    @include box-shadow($shadow-2dp);
}
.noUi-horizontal{
    .noUi-handle {
        width: 20px;
        height: 20px;
        left: -17px;
        top: -8px;
    }
    :after, :before{
        background: none;
    }
    .noUi-tooltip{
        bottom: 200%;
    }
}
.noUi-value-horizontal{
    -webkit-transform: translate3d(-50%,80%,0);
    transform: translate3d(-50%,80%,0);
}
.noUi-vertical .noUi-handle {
    margin-left: 5px;
    cursor: ns-resize;
}
.noUi-horizontal.noUi-extended {
    padding: 0 15px;
}
.noUi-horizontal.noUi-extended .noUi-origin  {
    right: -15px;
}
.noUi-background {
    height: 2px;
    margin: 20px 0;
}
.noUi-origin {
    margin: 0;
    border-radius: 0;
    height: 2px;
    background: #c8c8c8;
    &[style^="left: 0"] .noUi-handle {
        background-color: #fff;
        border: 2px solid #c8c8c8;
        &.noUi-active {
            border-width: 1px;
        }
    }
}
.noUi-target {
    border-radius: $border-radius;
    background: #e9e9ea;
    border: none;
}
.noUi-horizontal {
    height: 5px;
    margin: 15px 0;
}
.noUi-vertical {
    height: 100%;
    width: 2px;
    margin: 0 15px;
    display: inline-block;
}
[disabled].noUi-slider{
    opacity: 0.5;
}
[disabled] .noUi-handle {
    cursor: not-allowed;
}

.slider {
    background: #c8c8c8;
}

.slider {

    &.noUi-connect{
        background-color: theme-color("primary");
    }

    .noUi-handle{
        border-color: theme-color("primary");
    }

    &.slider-info{
        & .noUi-connect,
        &.noUi-connect{
            background-color: theme-color("info");
        }

        .noUi-handle{
            border-color: theme-color("info");
        }
    }
    &.slider-success{
        & .noUi-connect,
        &.noUi-connect{
            background-color: theme-color("success");
        }

        .noUi-handle{
            border-color: theme-color("success");
        }
    }
    &.slider-warning{
        & .noUi-connect,
        &.noUi-connect{
            background-color: theme-color("warning");
        }

        .noUi-handle{
            border-color: theme-color("warning");
        }
    }
    &.slider-danger{
        & .noUi-connect,
        &.noUi-connect{
            background-color: theme-color("danger");
        }

        .noUi-handle{
            border-color: theme-color("danger");
        }
    }

}
